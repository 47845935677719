import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Para navegar entre rutas
  const location = useLocation(); // Para obtener la ruta actual

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Función para manejar los enlaces, si estamos en la home, navega al anchor, si no, nos lleva a la home
  const handleNavigation = (e, target) => {
    e.preventDefault();
    if (location.pathname === '/') {
      const element = document.querySelector(target);
      if (element) {
        const headerOffset = 45; // Desplazamiento de 45px desde la parte superior
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    } else {
      // Si no estamos en la home, navegamos a la home con el anchor
      navigate(`/${target}`);
    }
    setIsOpen(false); // Cerrar el menú
  };

  return (
    <header className="fixed top-0 left-0 right-0 p-4 z-50 flex justify-between items-center">
      {/* Logo solo visible en mobile */}
      <div>
        <img src="/jungle.svg" alt="JUNGLE" className="w-7 md:w-8 h-auto" /> {/* Ajusta el tamaño del logo */}
      </div>

      {/* Burger icon */}
      <div
        className="space-y-2 cursor-pointer p-2 md:ml-auto"
        onClick={toggleMenu}
        style={{ width: '60px', height: '43px' }} // Ajustar tamaño como en la imagen
      >
        <span className="block w-full h-[4px] bg-black"></span>
        <span className="block w-full h-[4px] bg-black"></span>
        <span className="block w-full h-[4px] bg-black"></span>
      </div>

      {/* Full-screen menu */}
      <div
        className={`fixed inset-0 bg-[#00FF06] flex flex-col justify-start items-center transition-transform duration-300 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Close button */}
        <button
          className="absolute top-4 right-4 text-black text-4xl cursor-pointer"
          onClick={toggleMenu}
        >
          &times;
        </button>

        {/* Menu List */}
        <ul className="menu-list w-full h-full px-8 text-black font-light uppercase space-y-8 mt-12 md:mt-0 md:space-y-4 overflow-y-auto flex flex-col justify-start">
          <li className="text-[3.65rem] leading-[3.65rem] md:text-[11.4rem] md:leading-[11.4rem] text-left md:text-left">
            <a href="#elbolso" onClick={(e) => handleNavigation(e, '#elbolso')}>El Bolso →</a>
          </li>
          <li className="text-[3.65rem] leading-[3.65rem] md:text-[11.4rem] md:leading-[11.4rem] text-left md:text-right">
            <a href="#descripcion" onClick={(e) => handleNavigation(e, '#descripcion')}>Descripción →</a>
          </li>
          <li className="text-[3.65rem] leading-[3.65rem] md:text-[11.4rem] md:leading-[11.4rem] text-left md:text-left">
            <a href="#porefk" onClick={(e) => handleNavigation(e, '#porefk')}>Los Premios →</a>
          </li>
          <li className="text-[3.65rem] leading-[3.65rem] md:text-[11.4rem] md:leading-[11.4rem] text-left md:text-right">
            <a href="#get-yours" onClick={(e) => handleNavigation(e, '#get-yours')}>Consíguelo →</a>
          </li>
          <li className="text-[3.65rem] leading-[3.65rem] md:text-[11.4rem] md:leading-[11.4rem] text-left md:text-left">
            <Link to="/lookbook" onClick={toggleMenu}>
              Editorial →
            </Link>
          </li>
        </ul>

        {/* Imagen en la parte inferior izquierda en mobile */}
        <div className="absolute md:hidden bottom-4 left-4">
          <img src="/mobile.svg" alt="Logo Jungle" className="w-32 ml-2 h-auto" /> {/* Ajusta el tamaño de la imagen según lo necesario */}
        </div>
      </div>
    </header>
  );
};

export default Header;
