import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const location = useLocation(); // Usar para obtener el hash
  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; // Detectar dispositivos iOS

  // Manejar el scroll para ajustar la visibilidad del formulario al llegar al final de la página
  const handleScroll = () => {
    const scrolled = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (isMobile) {
      if (scrolled >= documentHeight - 50) {
        setIsFormVisible(true);
      }
    } else {
      if (scrolled >= documentHeight - 50) {
        setIsFormVisible(true);
      } else if (scrolled < documentHeight - 5) {
        setIsFormVisible(false);
      }
    }
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Desaparecer la capa sobrepuesta al hacer scroll solo en desktop
  const overlayVisible = isMobile ? true : scrollY < 800;

  // Efecto para hacer scroll al anchor cuando hay un hash en la URL con desplazamiento extra
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        const offset = 35; // Ajusta este valor para el desplazamiento adicional
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = window.pageYOffset + elementPosition - offset;

        // Desplazar con el margen superior
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Simulación del fondo "fixed" usando un contenedor */}
      <div
        className={`fixed top-0 left-0 w-full h-screen ${
          isIos ? 'bg-scroll' : 'bg-fixed'
        }`}
        style={{
          backgroundImage: 'url(/gif-bag.gif)',
          backgroundSize: isMobile ? '105%' : '48%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat', // Evita que el fondo se repita
          zIndex: -1,
        }}
      ></div>

      {/* Bloque del Logo - 100vh */}
      <div className="relative flex justify-center items-center h-screen mt-[-80px] mb-[80px]  md:mb-[110px] md:mt-[-85px] z-20">
        <img src="/junglecarrier-logo.svg" alt="Logo" width={isMobile ? '28%' : '10%'} className="z-20 mt-[50px] mt:mt-0" />
      </div>

      {/* Bloque de texto en un box más estrecho */}
      <div className="relative z-40 bg-opacity-80 px-4 mb-[170px]">
        <div className="text-left py-1 max-w-[90%] mx-auto">
          {/* Titular con fondo verde */}


          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12">
            El éxito no se compra. El éxito no se vende. El éxito no se regala. El éxito no se plagia. El éxito no se hereda. El éxito no se roba. El éxito no se devuelve. El éxito no se cambia. El éxito no se alquila. El éxito no es una moda. El éxito no se paga. El éxito se gana.
          </p>

          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12">
            Jungle Carrier®. Gánatelo.
          </p>
          
          <div className="bg-[#00FF06] inline-block text-black text-[2rem] md:mt-20 md:text-[5.5rem] leading-none mb-10 border border-black px-2 pt-[5px] md:pt-[15px]">
            <h2 id="elbolso" className="text-left">EL BOLSO</h2>
          </div>
          
          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12">
            Jungle Carrier es el primer bolso que sólo puede adquirirse si has sido uno de los galardonados en <span className='underline'>  <a href='https://www.premioseficacia.com/' target='_blank'>los premios Eficacia</a></span>. Por eso, su diseño único y original se completa con un trofeo en su interior.
          </p>
          
          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12">
          Además, sus medidas se ajustan a la perfección a las dimensiones del premio y, su larga correa, nos permite transportar el premio cómodamente a cualquier lugar, sin tener que preocuparnos de perderlo. 
          </p>
          
          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12">
          Jungle Carrier es combinable con cualquier prenda o accesorio, incluidos otros bolsos. O este mismo, en caso de haber ganado más de un premio Eficacia.
          </p>

          <div className="bg-[#00FF06] inline-block text-black text-[2rem] md:mt-20 md:text-[5.5rem] leading-none mb-10 border border-black px-2 pt-[5px] md:pt-[15px]">
            <h2 id="descripcion" className="text-left">DETALLES DEL PRODUCTO</h2>
          </div>
          
          <p className="text-[1rem] md:text-[3.2rem] leading-[1rem] md:leading-[3.2rem] text-black mb-12">
            • Bolso de piel<br/>
            • Azul Capri<br/>
            • Medidas: 18x16x10<br/>
            • Correa larga con hebillas laterales<br/>
            • Logo de Jungle en la parte inferior derecha<br/>
            • Tachuelas inferiores<br/>
            • Diseño adaptado al trofeo de los premios Eficacia<br/>
            • Diseñado por Jungle en colaboración con  <span className='underline'><a href="https://www.instagram.com/el__cardenal/" target='_blank'>El Cardenal</a></span><br/>
            • Fabricado en España<br/>
          </p>

          <div className="bg-[#00FF06] inline-block text-black text-[2rem] md:mt-20 md:text-[5.5rem] leading-none mb-10 border border-black px-2 pt-[5px] md:pt-[15px]">
            <h2 id="porefk" className="text-left">LOS PREMIOS EFK</h2>
          </div>
          
          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12">
          Los Premios a la Eficacia son un importante reconocimiento en la industria del marketing. ¿Su objetivo? Reconocer la contribución de la comunicación comercial a la consecución de los objetivos empresariales de las empresas anunciantes. 
          </p>
          
          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12">
          Para agencias y anunciantes, conseguir un Premio a la Eficacia es un proceso costoso que requiere muchas horas de trabajo y esfuerzo. Por eso, este galardón, se ha convertido, para todo el sector, en un sinónimo de éxito.
          </p>

          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12 underline">
         <a href='https://www.premioseficacia.com/' target='_blank'> Descubre más sobre los premios aquí. </a>          </p>

          <div className="bg-[#00FF06] inline-block text-black text-[2rem] md:mt-20 md:text-[5.5rem] leading-none mb-10 border border-black px-2 pt-[5px] md:pt-[15px]">
            <h2 id="get-yours" className="text-left">CÓMO CONSEGUIRLO</h2>
          </div>

          <p className="text-[2rem] md:text-[5.5rem] leading-[2rem] md:leading-[5.5rem] text-black mb-12">
          Ven con tu premio EFK 2024 a la salida del teatro y llévate un Jungle Carrier
          </p>
          <p className="text-[1rem] md:text-[4rem] leading-[1rem] md:leading-[4rem] text-black md:m-12">
          *No se entregará más de 1 bolso por agencia o marca.<br />
          *Los bolsos se repartirán por orden de llegada hasta agotar existencias.
          </p>
        </div>
      </div>

      {/* Formulario en el bloque de 100vh */}
      <div className="flex flex-col justify-center items-center h-[20vh] md:h-screen z-40 md:mt-[-205px] md:pb-[185px] z-20">
        <img src="/jungle.svg" alt="Jungle Logo" className="w-[8%] mt-[-180px] md:mt-[-15px] md:w-[7%]" />
      </div>
    </div>
  );
};

export default Home;
