import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Lookbook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [animate, setAnimate] = useState(false);
  const carouselRef = useRef(null); // Crear una referencia para el carrusel

  // Reutilizamos la función handleNavigation del Header
  const handleNavigation = (e, target) => {
    e.preventDefault();
    if (location.pathname === '/') {
      // Si ya estás en la página de inicio, desplázate al target
      const element = document.querySelector(target);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Si no estás en la página de inicio, navega a la home y añade el hash
      navigate(`/${target}`);
    }
  };

  useEffect(() => {
    // Iniciar la animación después de que el componente haya sido montado
    const timer = setTimeout(() => setAnimate(true), 500);
    return () => clearTimeout(timer);
  }, []);

  // Función para manejar el clic en las flechas
  const scrollCarousel = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = direction === 'left' ? -window.innerWidth / 2 : window.innerWidth / 2;
      carouselRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  // Crear spans para cada letra de "Lookbook"
  const lookbookLetters = "Lookbook".split("").map((letter, index) => (
    <span
      key={index}
      className={`inline-block transition-all duration-700 ${
        animate ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'
      }`}
      style={{ transitionDelay: `${index * 0.1}s` }}
    >
      {letter}
    </span>
  ));

  return (
    <div className="relative min-h-screen bg-appBg overflow-hidden">
      {/* Título "Lookbook" con animación de letras */}
      <h1 className="hidden md:block absolute top-0 left-0 right-0 text-black text-[8rem] md:text-[15rem] uppercase z-30 text-center md:text-left tracking-widest mt-10 md:mt-[-60px]">
       Editorial
      </h1>

      {/* Galería de imágenes */}
      <div
        className="relative flex flex-col md:flex-row md:overflow-x-auto overflow-y-auto md:snap-x snap-mandatory h-screen w-full px-5 pb-12 py-8 scrollbar-hide z-20"
        ref={carouselRef} // Referencia del contenedor del carrusel
      >
        {/* Imagen 1 */}
        <div className="w-full md:w-[33vw] h-auto md:h-full flex-shrink-0 snap-center px-2 mb-6 md:mb-0">
          <img
            src="./lkbk1.jpg"
            alt="Lookbook image 1"
            className="h-auto w-full md:h-full md:object-cover object-scale-down"
          />
        </div>

        {/* Imagen 2 */}
        <div className="w-full md:w-[33vw] h-auto md:h-full flex-shrink-0 snap-center px-2 mb-6 md:mb-0">
          <img
            src="./lkbk2.jpg"
            alt="Lookbook image 2"
            className="h-auto w-full md:h-full md:object-cover object-scale-down"
          />
        </div>

        {/* Imagen 3 */}
        <div className="w-full md:w-[33vw] h-auto md:h-full flex-shrink-0 snap-center px-2 mb-6 md:mb-0">
          <img
            src="./lkbk3.jpg"
            alt="Lookbook image 3"
            className="h-auto w-full md:h-full md:object-cover object-scale-down"
          />
        </div>

        {/* Imagen 4 */}
        <div className="w-full md:w-[33vw] h-auto md:h-full flex-shrink-0 snap-center px-2 mb-6 md:mb-0">
          <img
            src="./lkbk4.jpg"
            alt="Lookbook image 4"
            className="h-auto w-full md:h-full md:object-cover object-scale-down"
          />
        </div>

        {/* Imagen 5 */}
        <div className="w-full md:w-[33vw] h-auto md:h-full flex-shrink-0 snap-center px-2 mb-6 md:mb-0">
          <img
            src="./lkbk5.jpg"
            alt="Lookbook image 5"
            className="h-auto w-full md:h-full md:object-cover object-scale-down"
          />
        </div>

        {/* Imagen 6 */}
        <div className="w-full md:w-[33vw] h-auto md:h-full flex-shrink-0 snap-center px-2 mb-6 md:mb-0">
          <img
            src="./lkbk6.jpg"
            alt="Lookbook image 6"
            className="h-auto w-full md:h-full md:object-cover object-scale-down"
          />
        </div>

        {/* Imagen 7 */}
        <div className="w-full md:w-[33vw] h-auto md:h-full flex-shrink-0 snap-center px-2 mb-6 md:mb-0">
          <img
            src="./lkbk7.jpg"
            alt="Lookbook image 7"
            className="h-auto w-full md:h-full md:object-cover object-scale-down"
          />
        </div>

        {/* Imagen 8 */}
        <div className="w-full md:w-[33vw] h-auto md:h-full flex-shrink-0 snap-center px-2 mb-6 md:mb-0">
          <img
            src="./lkbk8.jpg"
            alt="Lookbook image 8"
            className="h-auto w-full md:h-full md:object-cover object-scale-down"
          />
        </div>
      </div>

      {/* Flechas de navegación solo para desktop */}
      <div
        className="hidden md:block absolute left-4 top-1/2 transform -translate-y-1/2 z-30 cursor-pointer"
        onClick={() => scrollCarousel('left')}
      >
        <span className="text-[6rem] text-[#00FF06] font-bold">←</span>
      </div>
      <div
        className="hidden md:block absolute right-4 top-1/2 transform -translate-y-1/2 z-30 cursor-pointer"
        onClick={() => scrollCarousel('right')}
      >
        <span className="text-[6rem] text-[#00FF06] font-bold">→</span>
      </div>

      {/* Botón "Get Yours" */}
      <div className="fixed md:absolute bottom-5 left-1/2 transform -translate-x-1/2 md:left-auto md:translate-x-0 md:bottom-0 md:right-0 mb-0 md:mb-5 md:mr-5 z-40 w-[80%] md:w-auto">
        <a
          href="#getyours"
          onClick={(e) => handleNavigation(e, '#get-yours')}
          className="bg-[#00FF06] text-black py-2 px-8 pt-5 md:px-8 text-[2.5rem] md:text-[4rem] uppercase tracking-wider shadow-lg hover:bg-[#00e906] transition duration-300 w-full md:w-auto text-center"
        >
          Consíguelo →
        </a>
      </div>
    </div>
  );
};

export default Lookbook;
