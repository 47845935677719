import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent'; // Importamos react-cookie-consent
import Home from './Pages/Home';
import Lookbook from './Pages/Lookbook';
import Header from './Components/Header'; // Importa tu componente Header

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false); // Estado para el consentimiento de cookies
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal de cookies

  // Añadimos el código de Google Analytics (solo si las cookies son aceptadas)
  useEffect(() => {
    if (cookiesAccepted) {
      // Solo cargamos GA si el usuario aceptó las cookies
      if (process.env.NODE_ENV === 'production') {
        const script = document.createElement('script');
        script.async = true;
        script.src =
          'https://www.googletagmanager.com/gtag/js?id=G-RZCBGC1XJP';
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag; // Hacemos gtag disponible globalmente
        gtag('js', new Date());
        gtag('config', 'G-RZCBGC1XJP', {
          page_path: window.location.pathname,
        });
      }
    }
  }, [cookiesAccepted]);

  // Seguimiento de vistas de página en SPA (aunque sea una sola página)
  useEffect(() => {
    if (cookiesAccepted && process.env.NODE_ENV === 'production') {
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname,
      });
    }
  }, [cookiesAccepted]);

  return (
    <Router>
      <Header /> {/* Header que estará presente en todas las páginas */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lookbook" element={<Lookbook />} />
      </Routes>

      {/* Aviso de Cookies en la parte inferior */}
      <CookieConsent
        location="bottom"
        buttonText="Aceptar"
        style={{
          background: '#00FF06',
          color: '#000',
          padding: '15px',
          fontSize: '14px',
          textAlign: 'center',
          borderTop: '2px solid #000',
        }}
        buttonStyle={{
          color: '#fff',
          backgroundColor: '#000',
          border: '1px solid #fff',
          fontSize: '14px',
          padding: '8px 16px',
        }}
        expires={365}
        onAccept={() => setCookiesAccepted(true)} // Actualizamos el estado al aceptar
      >
        Este sitio web utiliza cookies para mejorar la experiencia del usuario.{' '}
        <button
          style={{
            color: '#000',
            textDecoration: 'underline',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => setIsModalOpen(true)}
        >
          Más información
        </button>
      </CookieConsent>

      {/* Modal para mostrar información de cookies */}
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '80%',
              maxWidth: '500px',
              textAlign: 'center',
              color: '#000',
            }}
          >
            <h2>Política de Cookies</h2>
            <p style={{ marginBottom: '10px' }}>
              <strong>Actualizado:</strong> 20/9/2024
            </p>
            <p style={{ marginBottom: '10px' }}>
              <strong>¿Qué son las cookies?</strong>
              <br />
              Las cookies y tecnologías similares son pequeños archivos de texto
              o fragmentos de código que se descargan en su dispositivo cuando
              visita un sitio web. Ayudan al sitio web a reconocer su dispositivo
              y recordar información sobre su visita.
            </p>
            <p style={{ marginBottom: '10px' }}>
              <strong>¿Por qué utilizamos cookies?</strong>
              <br />
              Utilizamos cookies para mejorar y optimizar el sitio web, medir el
              tráfico y comprender cómo los usuarios interactúan con el sitio.
            </p>
            <p style={{ marginBottom: '10px' }}>
              <strong>Tipos de cookies que utilizamos:</strong>
              <br />
              <strong>Cookies necesarias:</strong> Estas cookies son esenciales
              para el funcionamiento del sitio web.
              <br />
              <strong>Cookies funcionales:</strong> Permiten funcionalidades
              mejoradas y personalización.
              <br />
              <strong>Cookies de análisis:</strong> Nos ayudan a entender cómo
              los visitantes interactúan con el sitio web.
            </p>
            <p style={{ marginBottom: '10px' }}>
              <strong>¿Cómo puedo desactivar o eliminar las cookies?</strong>
              <br />
              Puede configurar su navegador para rechazar cookies o eliminar
              cookies existentes. Tenga en cuenta que esto puede afectar la
              funcionalidad del sitio web.
            </p>
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                padding: '10px 20px',
                backgroundColor: '#000',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '4px',
                marginTop: '10px',
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </Router>
  );
}

export default App;
